@import "../../assets/index.scss";
@import "@styles/screen-sizes";

.score-info-card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 24px;
  background-color: var(--color-white);
  background-image: $img-score-info;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;

  & .label-value {
    gap: 4px;
  }

  .score-info-card-header {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    margin-bottom: 26px;
    position: relative;

    &.status_client {
      margin-bottom: 40px;
    }

    &_ref {
      width: 50%;
      position: absolute;
      right: -24px;
      top: -24px;
      padding: 30px 24px 10px 16px;
      border-radius: 0 24px 0 12px;
    }
  }

  .score-info-card-content {
    display: flex;
    gap: 32px;
    margin-bottom: 26px;

    &_text {
      max-width: 380px;
    }

    &_param {
      width: 124px;

      &:first-child {
        width: 95px;
      }

      &:last-child {
        &:first-child {
          text-wrap: nowrap;
        }
      }
    }
  }

  .score-info-card-text-content {
    margin-bottom: 32px;
  }


}

@media (max-width: $screen-mobile) {

  .score-info-card-wrapper {
    //background-size: cover;

    .score-info-card-header {
      gap: 30px;
    }
  }

  .score-info-card-header_param_referral {

    & .score-info-card-header_ref {
      width: 100%;
      top: 78px;
      right: 0;
      padding: 8px 12px;
      border-radius: 8px;
    }
  }
  .score-info-card-wrapper {
    .score-info-card-content {
      margin-bottom: 0;

      display: grid;
      grid-template: repeat(1, 1fr) / repeat(2, 1fr);
      row-gap: 14px;
      column-gap: 32px;

      &_param {
        width: 141px;

        &:last-child {
          width: 160px;

          span {
            &:first-child {
              text-wrap: nowrap;
            }

            &:last-child {
              width: 110px;
            }
          }
        }
      }
    }
  }
}
