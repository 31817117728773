@import "@styles/screen-sizes";

.registration-form {
  max-width: 456px;

  &__title {
    margin-bottom: 56px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__inputs {

      &_columns {
        margin-bottom: 16px;

        & > div {
          width: 48%;
        }

        @media (max-width: $screen-tablet) {
          & > div {
            width: 100%;
          }
        }

        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }

      &_rows {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

    }

    &__button {
      width: 100%;
    }
  }
}

@media (max-width: $screen-tablet) {
  .registration-form {
    max-width: 100%;
    min-width: 200px;
  }
}
