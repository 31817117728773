@import '@styles/screen-sizes';

.app-ui-carousel {
  width: 100%;

  img {
    border-radius: 24px;
  }


  &-slider {
    padding: 0 39px;

    .ant-carousel {
      .slick-slider {

        .slick-list {
          padding-top: 13px;

          .slick-track {
            display: flex;
            gap: 20px;
            width: 0 !important;
          }
        }
      }
    }

    .ant-carousel {
      .slick-arrow {
        .slick-prev,
        .slick-next {
          display: flex !important;
          z-index: 1;
          margin: 0;
          transform: translateY(-50%);
        }

        .slick-prev {
          left: -21px;

        }

        .slick-next {
          right: -21px;
        }

        &:hover, &:focus {
          .slick-prev, .slick-next {
            display: flex !important;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .app-ui-carousel {
    &-slider {
      padding: 0;
      width: 100%;

      .ant-carousel {
        .slick-arrow {

          .slick-prev {
            left: -280%;
          }

          .slick-next {
            right: -280%;
          }

          &:hover, &:focus {
            .slick-prev, .slick-next {
              display: flex !important;
            }
          }
        }
      }
    }

    &.slider-text {
      padding-left: 36px;
      width: calc(100% - 40px);

      .ant-carousel {
        .slick-prev {
          left: -20px;
        }

        .slick-next {
          right: -20px;
        }

        &:hover, &:focus {
          .slick-prev, .slick-next {
            display: flex !important;
          }
        }
      }
    }
  }
}

