.text-slider {
  display: flex;
  align-items: center;
  gap: 20px;

  .text-slider_item {
    line-height: 32px;
  }

  .slick-slide.slick-active.slick-current {
    border-bottom: 1.5px dashed var(--color-black) !important;

    .app-ui-text {
      color: var(--color-black) !important;
    }
  }

  .text-slider_item {
    line-height: 32px;

    &_active {
      border-bottom: 1.5px dashed var(--color-black);
    }
  }

  .text-slider-list_row {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .app-ui-carousel {
    &-slider {
      .ant-carousel {
        .slick-slider {
          .slick-list {
            padding-top: 0;

            .slick-track {

            }
          }
        }
      }

      .ant-carousel {
        .slick-arrow {
          .slick-prev,
          .slick-next {
          }

          .slick-prev {
          }

          .slick-next {
          }

          &:hover, &:focus {
            .slick-prev, .slick-next {

            }
          }
        }
      }
    }
  }
}
