@import "@styles/screen-sizes";

.home-page {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

@media (max-width: $screen-tablet) {
  .home-page {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: $screen-mobile) {
  .home-page {
    margin-bottom: 32px;
  }
}
