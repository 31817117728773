@import "@styles/colors.css";

.promo-slider {
  background: var(--color-empty);
  border-radius: 24px;
  &__item {
    position: relative;
    border-radius: 24px;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 250px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left bottom;
    }

    &_link {
      left: 24px;
      bottom: 24px;
      position: absolute;
    }
  }

  .ant-carousel {
    .slick-dots {
      li {
        width: 24px;
        background: var(--color-white);
        opacity: 0.5;
        border-radius: 23px;
        &.slick-active {
          width: 75px;
          opacity: 1;
        }
      }
    }
    .slick-dots-bottom {
      bottom: 24px;
      justify-content: flex-end;
      margin: 0 24px 0 0;
    }
  }

  &.mobile {
  }
  &.desktop {
  }
}


