.payment-order-error-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin: 90px 0 10px 0;

  .empty-data__wrapper {
    height: unset;
  }
  .empty-data__description {
    text-align: center;
  }

  img {
    max-width: 269px;
  }
}
