@import "@styles/screen-sizes";

.order-card {
  height: 129px;
  background-color: var(--color-white);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;

  .label-value {
    gap: 4px;
  }

  &.medium {
    min-height: 152px;
  }

  &.large {
    min-height: 164px;
    padding-top: 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      gap: 8px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: $screen-laptop) {
  .order-card {
    gap: 24px;

    &.large {
      height: 164px;
      padding: 20px;
    }

    &__header {
      &-left {

      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .order-card {
    justify-content: center;

    &.large {

    }

    &__header {
      &-left {
        flex-direction: column;
        gap: 8px
      }
    }
  }
}
