* {
  --color-green: #16C72E;
  --color-light-green: #54E168;
  --color-dark-green: #0CAE22;
  --color-dashed: #DDE0EE;
  --color-gray-mini: #EDEDF0;
  --color-gray-max: #6A6D7E;
  --color-gray-medium: #A9ADC5;
  --color-hover: #F5F7F8;
  --color-disabled: #DFE1EA;
  --color-error: #FF002E;
  --color-white: #FFFFFF;
  --color-black: #13141F;
  --color-blue: #0286FF;
  --color-orange: #FF9900;
  --color-light-gray: rgba(237, 237, 240, 0.5);
  --color-light-orange: rgba(255, 153, 0, 0.10);
  --color-light-green-bg: rgba(22, 199, 46, 0.25);
  --color-background: #F8FAFC;
  --color-empty: #F2F5F9;
}

