@import "@styles/screen-sizes";

.product-card-wrapper {
  width: 175px;
  padding: 8px;
  border: 1px solid var(--color-gray-mini);
  border-radius: 12px;
  position: relative;

  .product-card__bonus {
    position: absolute;
    top: -14px;
    right: -6px;
    z-index: 1;
  }

  .product-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    .product-card__img {
      width: 159px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-hover);
      border-radius: 6px;

      &_img {
        display: block;
        width: 50px;
        height: 88px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 36px;

        &-quantity {
        }


      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .product-card-wrapper {
    width: 148px;

    .product-card {
      .product-card__img {
        width: 132px;
        display: flex;

        &_img {
          display: block;
          width: 50px;
          height: 88px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .product-card-wrapper {
    width: 175px;

    .product-card {
      .product-card__img {
        width: 100%;
        display: flex;

        &_img {
          display: block;
          width: 50px;
          height: 88px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}
