@import '@styles/screen-sizes';

.orders-by-role {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 12px 16px 12px;
    height: 49px;

    &_summa {
      display: flex;
      gap: 24px;

      > div {
        gap: 4px;
      }
    }
  }

  .order-role-empty {
    height: fit-content;
    margin-top: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__dropdown {
      min-width: 170px;

      &_label {
        display: flex;
        gap: 8px;
        align-items: flex-end;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__empty-list {
    max-width: 458px;
    height: 346px;
  }
}

@media (max-width: $screen-laptop) {
  .orders-by-role {
    margin-bottom: 16px;

    &__header {
    }

    &__list {


      &__dropdown {

        &_label {

        }
      }
    }

    &__empty-list {
      width: 100%;
      max-width: 504px;
      height: 200px;
    }
  }
}


@media (max-width: $screen-tablet) {
  .orders-by-role {

    &__header {
      margin-top: 12px;

      &_summa {
        > div {
        }
      }
    }

    &__list {
      max-height: fit-content;
      overflow-y: hidden;

      &__dropdown {
        &_label {
        }
      }
    }

    &__empty-list {
      width: 100%;
      max-width: 100%;
      height: 346px;
    }
  }
}

@media (max-width: $screen-mobile) {

  .orders-by-role__header, .order-role-empty {
    display: none;
  }

  .orders-by-role {
    &__header {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      margin: 20px 0 32px 0;
      height: fit-content;

      &_summa {
        > div {
        }
      }
    }

    &__list {
      &__dropdown {
        &_label {
        }
      }
    }

    &__empty-list {
      width: 100%;
      max-width: 100%;
      height: 346px;
    }
  }
}
