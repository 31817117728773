@import "@styles/screen-sizes";

.product-card-slider-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;

  .slick-list {
    padding: 10px 0 0 0;
  }
}


@media (max-width: $screen-mobile) {
  .product-card-slider-list {
    width: 183px;
  }
}
