.app-ui-menu {
  margin: 0 8px;
  display: flex;
  gap: 4px;
  flex-direction: column;

  & .ant-menu {
    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--color-gray-medium);
      height: 44px;
      margin: 0;
      padding: 10px 12px;
      border-radius: 12px;

      &-selected {
        color: #13141F;
      }
    }

    &-item-active {
      color: var(--color-gray-max) !important;
      background-color: transparent !important;
    }

    &-title-content {
      font-size: 16px;
      font-family: "Averta CY", sans-serif;
    }
  }
}
