@import "./assets/index";
@import "@styles/screen-sizes";

.auth-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow-x: hidden;
  min-height: 100vh;

  .auth-img {
    height: 100%;
    background-size: cover;

    &.login {
      background-image: $img-auth-login;
    }

    &.recovery {
      background-image: $img-auth-recovery;
    }

    &.registration {
      background-image: $img-auth-registration;
    }

    &.reset {
      background-image: $img-auth-reset;
    }
  }

  .auth-content {
    padding: 64px;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @media (max-width: $screen-tablet) {
    grid-template-columns: 1fr;
    .auth-img {
      display: none;
    }
    .auth-content {
      padding: 14.67px 20px
    }
  }

  @media (max-width: $screen-laptop) {
    .auth-content {
      padding: 3.3%;
    }
  }
}
