.label-value {
    display: flex;
    flex-direction: column;
    gap: 8px;

  &.horizontal {
    flex-direction: row;
    align-items: flex-end;

    &.full {
      justify-content: space-between;
    }
  }
}
