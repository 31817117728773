.sidebar-footer {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 0 12px;
  align-items: center;

  & .sidebar-footer__menu-item {
    display: flex;
    gap: 8px;
    color: var(--color-gray-medium);
    align-items: center;
  }
}
