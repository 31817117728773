@import '@styles/screen-sizes';

.status-order-page {
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-skeleton {
    margin: 20px 0 0 0;
  }

  &-content {
    flex: 1;
    max-width: 1037px;

    &__title {
      margin: 32px 0;
    }
  }

  &-footer {
    flex: 1;
    display: flex;
    gap: 32px;

    .is-full  {
      width: max-content;
    }
    &__button {
      margin-top: 32px;
      max-width: 261px;
    }
  }
}

@media (max-width: $screen-tablet) {
  .status-order-page-footer {
    flex-direction: column;
    gap: 8px;
    .is-full  {
      width: 100%;
    }
  }
}

@media (max-width: $screen-laptop) {
  .status-order-page {
    &-content {
      max-width: 1016px;
      padding-right: 10px;

      .app-ui-carousel {
        &-slider {
          .ant-carousel {
            .slick-slider {
              .slick-list {
                .slick-track {
                  gap: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .status-order-page {
    &-content {
      .app-ui-carousel {
        &-slider {
          //width: 410px;
        }
      }
    }

    &-footer {
      &__button {
        max-width: unset;
      }
    }
  }
}
