@import "@styles/screen-sizes";

.auth-ui-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 3.3%;
  margin-bottom: 144.83px;
}

@media (max-height: $screen-tablet) {
  .auth-ui-header {
    margin-bottom: 15%;
  }
}



