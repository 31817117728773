@import "@styles/screen-sizes";

.personal-layout {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  background-color: var(--color-background);

  .personal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 0;
    overflow-y: auto;


    &__navbar {
      .personal-navbar {
        margin: 0 0 0 55px;
        max-width: 1408px;
      }
    }

    .navbar_sticky {
      position: sticky;
      top: 0;
      z-index: 100;
    }

    &__page {
      padding: 0 0 0 55px;
      max-width: 1461px;

      &::-webkit-scrollbar {
        display: none;
      }

      .additional-info-layout {
        display: grid;
        grid-template-columns: 459px minmax(200px, 933px);
        grid-gap: 16px;
        margin: 32px 0;

        &__right,
        &__left {
          display: flex;
        }
      }

      &-footer {
        margin: 32px 0 0 0;
      }
    }
  }
}

@media (max-width: $screen-laptop) {
  .personal-layout {

    .personal-content {
      padding: 0 20px 0 20px;

      &__navbar {
        .personal-navbar {
          max-width: 1025px;
          margin-left: 67px;
        }
      }

      &__page {
        max-width: 1091px;
        padding-left: 67px;

        .additional-info-layout {
          display: grid;
          grid-template-columns: 459px minmax(200px, 551px);
        }
      }
    }
  }
}

@media (max-width: $screen-tablet) and (min-width: $screen-mobile) {
  .personal-layout {

    .personal-content {
      padding: 0;

      &__navbar {

        .personal-navbar {
          margin: 0;
        }
      }

      &__page {
        height: 100%;
        display: flex;
        overflow-y: unset;
        flex-direction: column;
        padding: 0;
        align-items: center;

        .additional-info-layout {
          width: 100%;
          max-width: 600px;
          display: flex;
          flex-direction: column;
          gap: 32px;
          padding: 24px 0 32px 0;
          margin: 0;
        }

        &-footer {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .personal-layout {
    .personal-content {
      padding: 0;

      &__navbar {

        .personal-navbar {
          margin: 0;
        }
      }

      &__page {
        padding: 0 20px;

        .additional-info-layout {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
