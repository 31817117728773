@import "@styles/screen-sizes";

.sidebar {
  padding: 32px 0;
  background-color: var(--color-white);
  border-radius: 0 24px 24px 0;
}

.company-img__small {
  margin-bottom: 24px;
}
