.app-ui-radio {
  .ant-radio {

    &-input {
      display: none;
    }

    &-wrapper.primary, &-wrapper-checked.primary {
      &:hover .ant-radio,
      &:hover .ant-radio-inner {
        border-color: var(--color-gray-medium);
      }
    }

    &-wrapper.secondary, &-wrapper-checked.secondary {
      &:hover .ant-radio,
      &:hover .ant-radio-inner {
        border-color: var(--color-blue);
      }
    }

    &-wrapper.disabled, &-wrapper-checked.disabled {
      .ant-radio, .ant-radio-inner {
        background-color: transparent;
        border-width: 1.5px;
        border-radius: 50%;
        border-color: var(--color-disabled);
      }
    }

    &-inner {
      width: 24px;
      height: 24px;
      background-size: 100% 100%;
      border-width: 1.5px;
      background-color: transparent;

      &::after {
        display: none;
      }
    }

    &-wrapper {
      margin: 0;

      ::after {
        display: none;
      }

      &.ant-radio-wrapper-checked {
        .ant-radio-inner, .ant-radio {
          background-color: transparent;
          border-color: transparent  !important;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        &.primary {
          .ant-radio-inner {
            background-image: url('assets/radio-black.svg');
          }
        }

        &.secondary {
          .ant-radio-inner {
            background-image: url('assets/radio-blue.svg');
          }
        }

        &.disabled {
          .ant-radio-inner {
            background-image: url('assets/radio-disabled-on.svg');
          }
        }
      }
    }
  }
}
