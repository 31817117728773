.amount-products-controller {
  display: flex;
  gap: 8px;

  .ant-btn {
    &.is-icon-only {
      padding: 4px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
  }
}
