@import "@styles/screen-sizes";

.success-order-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    .order-payment-info-wrapper {
      max-width: 341px;
    }

    .order-delivery-info__content {
      justify-content: flex-start;
    }
  }
}

@media (max-width: $screen-tablet) {
  .success-order-form {
    &__product-list {
      width: 100%;
      display: flex;
      justify-content: center;

      .app-ui-carousel.app-ui-carousel-slider {
        padding: 0 70px;
      }
    }

    &__info {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;

      .order-payment-info-wrapper {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .success-order-form {
    &__product-list {
      .app-ui-carousel.app-ui-carousel-slider {
        padding: 0;
      }
    }
  }
}
