.app-select-card {
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid var(--color-gray-mini);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &.disabled {
    border-color: var(--color-disabled);
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
