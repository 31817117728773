.app-divider {

  .app-divider__line {
    height: 1px;
    width: 100%;
  }

  &.vertical {
    .app-divider__line {
      height: 100%;
      width: 1px;
      min-height: 10px;
    }
  }
}
