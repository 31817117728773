@import "@styles/screen-sizes";
@import "@styles/colors.css";

.public-footer {
  padding: 32px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--color-background);

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 32px;
    padding: 8px 10px;
    border-radius: 6px;

    a {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .telegram-link {
    background: linear-gradient(156deg, #33BEF0 0%, #0A85D9 100%);
    padding: 8px 6px;
    border-radius: 6px;
    height: 32px;
    display: flex;
    align-items: center;

    &__text {
      text-wrap: nowrap;
      margin: 0 0 0 8px;
      font-size: 15px;
    }

    a {
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 0 6px 0 0;
      width: 100%;
    }
  }

  .render-icon-text {
    display: flex;
    gap: 8px;

    &.center {
      align-items: center;
    }

    &.start {
      align-items: center;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    max-width: 1408px;

    &-item {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__block {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--map {
          img {
           border-radius: 24px;
          }
        }

        &-row {
          display: flex;
          gap: 24px;

          &.small {
            gap: 12px;
          }
        }
        &-column {
          display: flex;
          gap: 24px;
          flex-direction: column;

          &.small {
            gap: 12px;
          }

          &.align-end {
            align-self: flex-end;
          }
          &.align-start {
            align-self: flex-start;
          }

          &.gap-0 {
            gap: 0;
          }
        }

        &.logo,
        &.with-map {
          margin: auto 0 0 0;
        }

        &--map {
          display: flex;
        }
      }

      &.central-office {
        background-color: var(--color-hover);
        border-radius: 24px;
        max-width: 434px;
      }
      &.belarus {
        border-left: 1px solid var(--color-disabled);
        max-width: 434px;
      }
      .map {
        max-width: 499px;
      }

      .footer-logo {
        width: 136px;
        height: 58px;
      }
    }
  }
}

@media (max-width: $screen-laptop) {
  .public-footer {
    .social-link,
    .telegram-link {
      width: 100%;
      justify-content: flex-start;
    }
  }

}

@media (max-width: $screen-laptop) and (min-width: $screen-tablet) {
  .public-footer {
    padding: 24px 0;

    &__content {
      &-item {
        padding: 16px 16px 0 16px;

        &.central-office {
          background-color: var(--color-hover);
          border-radius: 24px;
          max-width: 330px;
          padding: 16px;
        }
        &.belarus {
          border-left: 1px solid var(--color-disabled);
          max-width: 330px;
        }
        &__block {
          &-row {
            &.data {
              flex-direction: column;
              gap: 6px;
            }
          }

          &--map {
            img {
              height: 172px;
            }
          }

          .sell-info {
            max-width: 140px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: $screen-tablet) {
  .public-footer {
    padding: 32px 0 0 0;
    &__content {
      flex-direction: column;
      max-width: 600px;
      &-item {

        &.belarus,
        &.map,
        &.central-office {
          width: 100%;
          max-width: unset;
        }

        &.belarus {
          border-left: none;
          border-top: 1px solid var(--color-disabled);

          .render-icon-text {
            max-width: 350px;
          }
        }

        &__block {

          &-row {
            &.data {
              justify-content: space-between;
            }
          }

          &--map {
            img {
              max-width: 552px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $screen-mobile) {
  .public-footer {
    &__content {
      &-item {

        &__block {

          &-row {
            &.data {
              flex-wrap: wrap;
              justify-content: flex-start;
              gap: 12px;
            }
          }

          &--map {
            img {
              height: 162px;
              max-width: unset;
            }
          }
        }
      }
    }
  }
}
