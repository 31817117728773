@import '@styles/screen-sizes';

.app-ui-table {

  .ant-table {
    border-radius: 24px 24px 0 0;
    padding: 0 8px;

    .ant-table-title {
      padding: 24px 8px 10px 8px;
    }

    .ant-table-thead {
      tr {
        th {
          padding: 14px 16px;
          background-color: transparent;

          &::before {
            display: none;
          }
        }
      }
    }

    .ant-table-body {
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-disabled);
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: var(--color-hover);
      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .ant-table-wrapper {
    .ant-table-cell {
    }
  }

  .ant-table-body {
    .ant-table-cell {
      padding: 16px 10px !important;
    }
  }

}
