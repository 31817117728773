@import "@styles/screen-sizes";

.public-registration-block {
  background-color: var(--color-white);
  max-width: 380px;
  min-width: 340px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;

  &__text {
    padding: 16px 24px 0 24px;
    text-align: center
  }

  &__controls {
    display: flex;

    .app-ui-link {
      width: 100%;

      .app-ui-button {
        .ant-btn {
          height: 44px;
        }
      }

      &:last-child {
        .app-ui-button {
          .ant-btn {
            border-radius: 16px 0;
          }
        }
      }
    }
  }
}


@media (max-width: $screen-tablet) {
  .public-registration-block {
    max-width: 100%;
    width: 100%;

    &__text {
      margin: 0 auto;
    }
  }
}
