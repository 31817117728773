@import "@styles/screen-sizes";

.auth-header-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-gray-medium);
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: var(--color-gray-mini);

    .app-ui-link {
      background-color: transparent;
      color: var(--color-gray-mini);
    }
  }

  &:active {
    color: var(--color-gray-max);
  }

  &:disabled {
    color: var(--color-dashed);
  }

  &.adaptive {
    color: var(--color-black);
  }
}

@media (max-width: $screen-tablet) {
  .auth-header-link {
    color: var(--color-black);
  }
}
