@import "@styles/screen-sizes";

.order-form-products {
  border-radius: 24px;
  background: var(--color-white);
  padding: 24px 24px 24px 24px;
  min-width: 340px;
  max-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;

  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__labels {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__hint {
      text-align: center;
    }

    &__prices {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &__results {
      border-radius: 12px;
      padding: 8px 12px;
      background: var(--color-light-orange);
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.loading {
    position: relative;
    &:after {
      border-radius: 16px;
      position: absolute;
      width: 100%;
      content: '';
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(80, 80, 88, 0.1);
    }
  }
}


@media (max-width: $screen-tablet) and (min-width: $screen-mobile) {
  .order-form-products {
    width: 100%;
    max-width: 600px;

    &.public_products {
      margin-left: 20px;
    }
  }
}

@media (max-width: $screen-mobile) {
  .order-form-products {
    width: 100%;
    margin: 0 9px 0 28px;
    min-width: 345px;
    max-width: 600px;
  }

  .public_products {
    margin: 0 20px 0 20px;
  }
}

@media (max-width: $screen-mobile) and (min-width: 601px) {
  .public_products {
    margin: 0 26px 0 15px;
  }
}

