@import "@styles/screen-sizes";

.app-ui-modal-wrapper {

  .ant-modal {
    &-content {
      border-radius: 24px;
      padding: 24px;
    }

    &-close {
      display: none;
    }

    & .app-ui-modal {
      &__header {
        margin-bottom: 32px;

        &_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          &_close {
            margin-left: auto;
          }
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: $screen-mobile) {
  .app-ui-modal-wrapper {

    .ant-modal {
      &-content {

      }

      &-close {
      }

      & .app-ui-modal {
        &__header {

          &_title {
            align-items: flex-start;
            .title-h3 {
              font-size: 28px;
              line-height: 28px;
            }
          }
          &_description{
            .app-ui-text {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }

      &-body {

      }
    }
  }
}



