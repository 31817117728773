.app-ui-button {

  // отключение анимации подсветки клика
  & .ant-wave {
    display: none;
  }

  button::before {
    display: none;
  }

  &.is-full {
    width: 100%;
  }

  .ant-btn {
    width: inherit;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .ant-btn-loading-icon {
      position: absolute;
      right: 20px;
    }

    &.is-icon-only {
      padding: 15px;
      height: 30px;
      width: 30px;
      border-radius: 8px;
    }

    &.large {
      padding: 15px 24px;
      height: 54px;
      min-width: 54px;
      border-radius: 12px;
    }

    &.small {
      padding: 6px;
      height: 30px;
      border-radius: 8px;
    }

    &.medium {
      border-radius: 8px;
      height: 36px;
      padding: 7px 16px 9px 16px;
    }

    &:focus-visible {
      outline: none;
    }
  }

  //DefaultType
  & .ant-btn-default {
    border-color: var(--color-gray-mini);
    color: var(--color-black);

    &:not(:disabled) {
      &:hover {
        color: var(--color-gray-medium);
        border-color: var(--color-gray-max);
      }

      &:active {
        color: var(--color-gray-max);
        border-color: var(--color-gray-max);
      }
    }

    &:disabled {
      cursor: not-allowed;
      background: transparent;
      color: var(--color-dashed);
      border-color: var(--color-dashed);
    }
  }

  //MenuType
  &.is-menu-button {
    & .ant-btn {
      height: 36px;
      padding: 7px 12px 9px 12px;
      box-shadow: none;
      border: none;
      color: var(--color-gray-medium);


      // remove wave onClick animation
      & .ant-wave {
        display: none;
      }

      &:hover {
        color: var(--color-gray-max);
      }

      &:active {
        background-color: var(--color-hover);
        color: var(--color-black);
      }
    }
  }

  // BlackType
  &.is-black-button .ant-btn-default {
    border-color: transparent;
    background-color: var(--color-black);
    color: var(--color-white);

    &:not(:disabled) {
      &:hover {
        background-color: var(--color-gray-medium);
        color: var(--color-white);
        border-color: transparent;
      }

      &:active {
        background-color: var(--color-gray-max);
        color: var(--color-white);
        border-color: transparent;
      }
    }

    &:disabled {
      background: var(--color-disabled);
      color: var(--color-white);
      border-color: var(--color-dashed);
    }
  }

  // GrayMaxType
  &.is-gray-max-button .ant-btn-default {
    color: var(--color-gray-max);
    background-color: transparent;
    border: 1px solid var(--color-gray-mini);

    &:not(:disabled) {
      &:hover {
        color: var(--color-gray-max);
        border-color: var(--color-gray-max);
      }

      &:active {
        border-color: transparent;
        color: var(--color-white);
        background-color: var(--color-gray-max);
      }
    }

    &:disabled {
      border-color: transparent;
      color: var(--color-white);
      background-color: var(--color-disabled);
    }
  }

  &.is-menu-text {
    & .app-ui-text {
      width: 64px;
      text-align: start;
    }
  }

  //PrimaryType
  & .ant-btn-primary {
    background-color: var(--color-green);
    border: none;

    &:not(:disabled) {
      &:hover {
        background-color: var(--color-light-green);
      }

      &:active {
        background-color: var(--color-dark-green);
      }
    }

    &:disabled {
      background: var(--color-dashed);
      color: var(--color-white);
    }
  }

  //TextType
  & .ant-btn-text {
    color: var(--color-gray-medium);
    padding: 0;
    height: auto;

    &:not(:disabled) {
      &:hover {
        background-color: transparent;
        color: var(--color-gray-mini);
      }

      &:active {
        color: var(--color-gray-max);
      }
    }

    &:disabled {
      color: var(--color-dashed);
    }
  }

  //LinkType
  & .ant-btn-link {
    color: var(--color-black);
    padding: 0;
    height: auto;

    &:not(:disabled) {
      &:hover {
        background-color: transparent;
        color: var(--color-gray-medium);
      }

      &:active {
        color: var(--color-gray-max);
      }
    }

    &:disabled {
      color: var(--color-dashed);
    }
  }
}




