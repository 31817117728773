.app-ui-select {
  height: 54px;
  position: relative;

  & .label {
    z-index: 1;
    transition: all 0.3s ease;
    color: var(--color-gray-medium);
    position: absolute;
    left: 14px;
    pointer-events: none;

    &.app-input-left-icon-label {
      left: 52px;
    }

    &.label-small {
      top: 10px
    }

    &.label-placeholder {
      top: 17px
    }
  }

  & .icon {
    position: absolute;
    right: 0;
    top: 6px;
  }

  & .ant-select {
    width: 100%;

    & .ant-select-selector {
      background-color: transparent;
      font-size: 16px;
      line-height: 16px;
      padding: 24px 14px 10px 14px;
      height: 54px;
      border-radius: 12px;
      border-color: var(--color-gray-mini);

      &:hover {
        border-color: var(--color-gray-mini) !important;
      }

      & #select {
        opacity: 1;
      }

      & .ant-select-selection-placeholder {
        opacity: 0;
        padding-inline-end: 20px;
      }
    }

    &-focused {
      & .ant-select-selector {
        box-shadow: none !important;
        border-color: var(--color-blue) !important;

        &:hover {
          border-color: var(--color-blue) !important;
        }
      }
    }
  }

  &-filled {
    & .ant-select {
      .ant-select-selector {
        border-color: var(--color-gray-medium) !important;

        &:hover {
          border-color: var(--color-gray-medium) !important;
        }
      }
    }
  }
}
