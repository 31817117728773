@import "@styles/images";

.delivery-point-info-balloon {
  height: 100%;

  .delivery-point-info-card {
    background: var(--color-white);
    height: 100%;

    &-description {
      &__title {
        margin: 0;
      }
    }
  }
}

.point-portal {
  position: relative;
  min-width: 307px;
  max-width: 360px;
  width: 100%;
  min-height: 220px;
  max-height: fit-content;
}


.ymaps-2-1-79-balloon__close {
  margin-right: 8px !important;

  &-button {
    opacity: 1 !important;
  }
}

.ymaps-2-1-79-balloon {
  left: -60px !important;
  top: -200px !important;
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__tail {
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__content {
  padding: 0 !important;
  background: transparent !important;
  margin: 0 !important;

  [id^='id_'] {
    height: fit-content !important;
    min-width: 307px !important;
    max-width: 360px !important;
    width: 100% !important;

    .delivery-point-info-card-description__work-time {
      width: max-content;
    }
  }
}

.ymaps-2-1-79-balloon__layout {
  background: transparent !important;
  border: none !important;
}

[class*="copyrights-pane"] {
  display: none !important;
}
