@import "@styles/screen-sizes";

.ant-layout-sider {
  height: 100%;
  transition: width 1s ease;

  transition-duration: 0.2s, 0s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  transition-property: all, width;
}

.ant-layout-sider-collapsed {
  .ant-menu {
    width: 44px;

    &-item {
    }

    &-title-content {
      display: none;
    }
  }

}

.ant-layout-sider-children {
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-ui-sider {

    &__header {
      padding: 0 20px;
      margin-bottom: 32px;
    }

    &__menu {
      flex-grow: 1;
    }

    &__footer {
      padding-top: 24px;
      border-top: 1px solid var(--color-hover);
      text-wrap: nowrap;
    }
  }
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {

    .app-ui-sider {

      &__header {
        padding: 0 10px;
      }

      &__menu {
      }

      &__footer {

        .sidebar-footer {
        }
      }
    }
  }
}
