.app-ui-text {
  display: flex;
  flex-direction: row;
  width: fit-content;

  &.nowrap {
    white-space: nowrap;
  }

  &.ellipses {
    width: inherit;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.crossed-out {
    text-decoration: line-through;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.gotham {
    font-family: 'Gotham Pro', sans-serif;
  }

  &.averta {
    font-family: 'Averta CY', sans-serif;
  }

  &.codec {
    font-family: 'Codec Pro', sans-serif;
  }

  span {
    white-space: pre-line;
  }

  &.title {
    font-size: 38px;
    font-weight: 300;
    line-height: 33px;
  }

  &.title-h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
  }

  &.title-h4 {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
  }

  &.title-h5 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  &.title-h6 {
    font-size: 22px;
    line-height: 28px;
  }

  &.subtitle {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
  }

  &.note {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }

  &.note-bold {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }

  &.body {
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }

  &.body-light {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  &.body-bold {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &.signature {
    font-size: 12px;
    line-height: 11px;
    font-weight: 300;
  }

  &.signature-bold {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }

  &.small {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  &.fw-600 {
    font-weight: 600;
  }

  &.fw-300 {
    font-weight: 300;
  }

  &.fw-400 {
    font-weight: 400;
  }
}
