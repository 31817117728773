@import "@styles/screen-sizes";

.app-ui-prompt {
  position: relative;

  &-content {
    width: 173px;
    height: 44px;
    background-image: url('assets/propmt-blue.svg');
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    place-items: center;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-50%, -50%) translateX(calc(50% + 16px));
  }

  @media (max-width: $screen-laptop) {
    &-content {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(calc(-100% + 15px));
    }
  }
}
