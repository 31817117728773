.app-ui-checkbox {
  .ant-checkbox {
    &-input {
      display: none;
    }

    &-wrapper.primary, &-wrapper-checked.primary {
      &:hover .ant-checkbox,
      &:hover .ant-checkbox-inner {
        border-color: var(--color-gray-medium);
      }
    }

    &-wrapper.secondary, &-wrapper-checked.secondary {
      &:hover .ant-checkbox,
      &:hover .ant-checkbox-inner {
        border-color: var(--color-secondary);
      }
    }

    &-wrapper.disabled, &-wrapper-checked.disabled {
      .ant-checkbox, .ant-checkbox-inner {
        border-color: var(--color-disabled);
        border-radius: 8px;
      }
    }


    &-inner {
      width: 24px;
      height: 24px;
      background-size: 100% 100%;
      border-radius: 8px;
      background-color: transparent;
      border-width: 1.5px;

      &::after {
        display: none;
      }
    }

    &-wrapper {
      > span {
        align-self: center;
      }

      &.ant-checkbox-wrapper-checked {
        .ant-checkbox-inner, .ant-checkbox {
          background-color: transparent !important;
          border-color: transparent;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.primary {
          .ant-checkbox-inner {
            background-image: url('assets/check-box-black.svg');
          }
        }

        &.secondary {
          .ant-checkbox-inner {
            background-image: url('assets/check-box-blue.svg');
          }
        }

        &.disabled {
          .ant-checkbox-inner {
            background-image: url('assets/check-box-disabled.svg');
          }
        }
      }
    }
  }
}


