@import "@styles/screen-sizes";

.ant-drawer {
  &-content-wrapper {
    box-shadow: none !important;
    width: fit-content !important;
  }

  &-body {
    overflow-x: hidden !important;
    padding: 32px 0 !important;
  }
}
