.app-ui-dropdown-select {

  &__label {
    display: flex;
    gap: 4px;
    align-items: end;
    cursor: pointer;

    &_child {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
}

.arrow-center {
  .ant-popover-arrow {
    right: 43% !important;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    &-option {
      color: var(--color-gray-max);

      &:hover {
        background-color: var(--color-hover);
      }

      &-selected {
        background-color: var(--color-hover) !important;
      }

      &-active {
        color: var(--color-black);
        background-color: var(--color-hover);
      }
      &-content {
      }
    }
  }
}
