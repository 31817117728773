.cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin: 36px 0 10px 0;

  .empty-data__wrapper {
    height: unset;
  }

  img {
    max-width: 269px;
  }
}
