@import "@styles/screen-sizes";

.create-order-form {
  position: relative;
  display: grid;
  grid-template-columns: minmax(320px, 1052px) 380px;
  min-height: calc(100vh - (85px + 32px + 32px + 45px));
  grid-column-gap: 20px;
  height: fit-content;

  &.with-promo {
    min-height: calc(100vh - (85px + 32px + 32px + 45px + 120px + 32px));

    .create-order-form__form {
      padding-bottom: 64px;
    }
  }

  &__promo {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
  }

  &.empty {
    grid-template-columns: 1fr;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 32px;

    &_content {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      &-step {
        &__inputs {
          display: grid;
          grid-template-areas:
          "name name"
          "phone email"
          "address address"
          "comment comment";
          gap: 17px;
          row-gap: 20px;

          &.with-postal-code {
            grid-template-areas:
          "name name"
          "phone email"
          "address address"
          "index index"
          "comment comment";
          }

          .form {
            &_name {
              grid-area: name;
            }

            &_phone {
              grid-area: phone;
            }

            &_email {
              grid-area: email;
            }

            &_address {
              grid-area: address;
            }

            &_index {
              grid-area: index;
            }

            &_comment {
              grid-area: comment;
            }
          }
        }

        &.step-1 {
          max-width: 488px;
        }

        &.step-2 {
        }

        &.step-3 {
        }

        &.step-4 {
          .ant-checkbox-wrapper {
            //text-wrap: nowrap;
          }

          .agreement-inks {
            color: var(--color-gray-medium);
            display: flex;
            flex-wrap: wrap;
            white-space: break-spaces;
          }

          @media (max-width: $screen-laptop) {
            .agreement-inks {

            }
          }
        }

        &.step-5 {
        }

        &__title {
          margin-bottom: 24px;
        }

        .map-container, .delivery-info-container {
          overflow: hidden;
          display: flex;
          gap: 16px;
          margin: 32px 0 0 0;
          position: relative;

          &__map {
            width: 100%;
            max-width: 1052px;

            &-map {
              border-radius: 16px;
              overflow: hidden;
            }

            &-filters {
              display: flex;
              gap: 32px;
              align-items: center;
              margin: 0 0 32px 0;

              .app-search-input {
                width: 100%;
                max-width: 488px;
              }

              &-checkboxes {
                display: flex;
                gap: 16px;
                align-items: center;
                flex-wrap: wrap;

                .app-ui-text {
                  text-wrap: nowrap;
                }
              }
            }
          }

          &.hidden {
            display: none;
          }
        }
      }
    }

    &_footer {
      max-width: 222px;
    }
  }

  &__search-input__item {
    padding: 12px 0 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-title {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &__products {
    position: sticky;
    top: 126px;
    width: 100%;
    display: flex;
    align-self: flex-start;
    justify-content: flex-end;
    z-index: 1;
    flex-direction: column;
    gap: 26px;
  }

  &.public {
    .create-order-form__products {
      top: 125px;
    }
  }
}


@media (max-width: $screen-laptop) {
  .create-order {
    &-form {
      &__form {
        &_content {
          &-step {
            .map {
              &-container {
                &__map {
                  &-filters {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .create-order {
    &-form {
      max-width: 600px;

      grid-template-columns: 1fr;

      &__form {
        justify-content: flex-start;
        height: calc(100% - 180px);

        &__products {
        }

        &_content {
          &-step {
            &__inputs {


              gap: 17px;

              display: grid;
              grid-template-areas:
          "name name"
          "phone email"
          "address address"
          "comment comment";

              &.with-postal-code {
                grid-template-areas:
          "name name"
          "phone email"
          "address address"
          "index ."
          "comment comment";
              }
            }

            &.step-1 {
              max-width: 100%;
            }

            .map-container, .delivery-info-container {
              &__map {
                &-map {
                  overflow: hidden;
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100vw;
                  height: 100vh;
                  margin: 0;
                  z-index: 3;
                  .ymaps-2-1-79-user-selection-none {
                    //z-index: 0!important;
                  }
                }

                &__back-button {
                  position: fixed;
                  top: 20px;
                  left: 20px;
                  z-index: 4;
                }

                &-filters {
                  position: fixed;
                  background: var(--color-white);
                  top: 50px;
                  left: 50%;
                  transform: translateX(calc(-50%));
                  z-index: 4;
                  flex-direction: column;
                  align-items: flex-start;
                  border-radius: 24px;
                  padding: 16px;
                  gap: 16px;
                  width: 100%;
                  max-width: 600px;

                  .app-search-input {
                    width: 100%;
                    max-width: unset;
                  }

                  &-input {
                    width: 100%;
                    min-width: unset;
                  }
                }
              }

              &.hidden {
                display: none;
              }


            }
          }
        }

        &_footer {
          max-width: 100%;
        }
      }

      &__products {
        width: 100%;
        position: fixed;
        right: 10px;
        bottom: 20px;
        top: unset;
        flex-direction: row;
        justify-content: center;
      }

      &.with-promo {
        .create-order-form__form {
          padding-bottom: 32px;
        }

        .create-order-form__products {
          top: unset;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .create-order {

    &-form {
      &__form {
        &__products {
        }

        &_content {

          &-step {
            &__inputs {
              display: grid;
              grid-template-areas:
          "name name"
          "phone phone"
          "email email"
          "address address"
          "comment comment";
              gap: 17px;

              &.with-postal-code {
                grid-template-areas:
          "name name"
          "phone phone"
          "email email"
          "address address"
          "index index"
          "comment comment";
              }
            }

            &.step-1 {
            }

            .map-container, .delivery-info-container {
              &__map {
                &__back-button {
                }

                &-filters {
                  max-width: 95%;
                }
              }

              &.hidden {
              }
            }
          }
        }

        &_footer {
        }
      }

      &__products {
      }

      &.with-promo {
        .create-order-form__products {
          max-width: 600px;
          right: 0;
        }
      }
    }
  }
}


