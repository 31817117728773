@import '@styles/screen-sizes';

.active-orders__header-dropdown {
  &.app-ui-popover {
    .ant-popover-inner {
      .app-ui-option-list_item_content {
        width: 100%;
      }
    }
  }
}


.active-orders {
  width: 693px;

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 21px;
    gap: 24px;

    .note {
      font-size: 20px;
      line-height: 26px;
    }

    .subtitle {
      line-height: 26px;
    }
  }

  &__content {
    background-color: var(--color-white);
    border-radius: 24px;
    padding: 18px 24px 24px 24px;

    &_list {
      max-width: 648px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 32px;

      &__dropdown {
        min-width: 170px;

        &_label {
          display: flex;
          gap: 8px;
          align-items: flex-end;
        }
      }
    }

    &__info {
      &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .body {
          line-height: 18px;
        }
      }

      &_content {
        display: grid;
        justify-content: space-between;
        grid-template-columns: 133px 247px 119px 90px;
      }
    }
  }

  .empty-active-order {
    height: 405px;
  }
}

@media (max-width: $screen-laptop) {
  .active-orders {
    max-width: 504px;

    &__header {
      &_slider {
        width: 180px;
      }
    }

    &__content {
      &_list {
      }

      &__info {
        &_header {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .label-value {
        }

        &_content {
          grid-template-columns: 1fr 1fr;
          row-gap: 24px;
        }
      }
    }

    .active-orders__list {
      &__dropdown {

        .app-ui-dropdown-select__label {
          .app-ui-text {
          }
        }

        &_label {

        }
      }
    }
  }
}


@media (max-width: $screen-tablet) {
  .active-orders {
    width: 632px;
    max-width: 100%;
    flex: 1;

    &__header {
      margin-bottom: 20px;
      justify-content: space-between;

      &_slider {
        width: 100%;
      }
    }

    &__content {
      &_list {
        max-width: 100%;

        .app-ui-carousel-slider {
          padding: 0 33px;
        }
      }

      &__info {
        &_header {
          align-items: flex-start;
          margin-bottom: 16px;
        }

        .label-value {
        }

        &_content {

        }
      }
    }

    .active-orders__list {
      &__dropdown {
        max-width: 100px;
        min-width: 80px;

        .app-ui-dropdown-select__label {
          gap: 0;
          align-items: flex-end;

          .app-ui-text {
            white-space: nowrap;
          }
        }

        &_label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .active-orders {
    &__header {


      &_slider {
        width: 100%
      }
    }

    &__content {
      &_list {
        .product-card-slider-list.carousel {
          display: flex;
          justify-content: center;
          width: 242px;
          margin: 0 auto;
        }
      }

      &__info {
        &_header {
        }

        .label-value {
        }

        &_content {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }

    .active-orders__list {
      &__dropdown {
        .app-ui-dropdown-select__label {
          .app-ui-text {
          }
        }

        &_label {
        }
      }
    }
  }
}
