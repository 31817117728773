.app-vertical-scroll-list {
  overflow-y: auto;
  max-height: 360px;
  min-height: 83px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-disabled);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-hover);
  }

  &__item {
    padding: 0 12px 0 0;
  }
}
