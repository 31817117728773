.sort-toggler {
  cursor: pointer;
  display: flex;
  gap: 8px;

  &.align {
    &-center {
      justify-content: center;
    }
    &-start {
      justify-content: start;
    }
    &-end {
      justify-content: end;
    }
  }
}
