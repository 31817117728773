@import "@styles/screen-sizes";

.recovery-form {
  width: 400px;

  &__title {
    margin-bottom: 16px;
  }

  &__subtitle {
    color: var(--color-gray-max);
    margin-bottom: 32px;
  }


  &__controls {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__button {
      width: 100%;
    }
  }
}

@media (max-width: $screen-laptop) {
  .recovery-form {
    width: 100%;
    min-width: 200px;
  }
}
