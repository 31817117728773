@import "@styles/screen-sizes";

.choose-payment-method {
  display: flex;
  gap: 23px;

  .app-select-card {
    text-wrap: nowrap;
  }

  &__coins {
    &-payment {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__input {
        display: flex;
        align-items: center;
        gap: 8px;

        &-hint {
          display: flex;
          flex-direction: column;
          gap: 4px;
          padding: 12px;

          &-popup {
            .ant-popover-inner {
              padding: 0;
              max-width: 290px;
            }
          }

          &__title {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          &__footer {
            .app-ui-text {
              display: unset;
            }
          }
        }
      }

      .app-ui-input {
        max-width: 161px;
      }
      .ant-input {
        text-align: center;
      }
    }
  }
}

@media (max-width: $screen-laptop) {
  .choose-payment-method {
    grid-template-columns: repeat(2, minmax(100px, 190px)); 
    gap: 24px;
    display: grid;

    .app-select-card {
      &:nth-child(3) {
        grid-column: 1 / -1; 
      }
    }

    &__coins {
      &-payment {
        flex-direction: column;
        max-width: 100%;
      }
    }

    &__divider {
      display: none;
    }
  }
}

@media (max-width: $screen-tablet) {
  .choose-payment-method {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    &__coins {
      &-payment {
        grid-column: span 1; 
      }
    }
  }
}
