.adaptive-navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: 12px 15px 12px 20px;
  align-items: center;

  .height-z-index_button {
    z-index: 1080;
  }
}
