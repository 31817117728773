.app-ui-phone-input {
  width: 100%;
  display: flex;
  gap: 2px;

  & .app-ui-input .ant-input.app-input-icon {
    padding-right: 26px;
  }

  &_code {
    width: 78px;

    &.active {
      border-color: var(--color-green);
    }
  }

}
