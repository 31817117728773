@import "@styles/screen-sizes";


.order-details-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &-title {
    display: flex;
    gap: 24px;
  }

  &-product-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &-payment {
  }

  &-delivery {
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__content {
      display: flex;
      gap: 16px;

      > div {
        width: 308px;
      }
    }
  }

  &-footer {
    padding-top: 24px;
    border-top: 1px solid var(--color-gray-mini);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &__content {
      display: flex;
      gap: 20px;
    }
  }

  @media (max-width: $screen-tablet) {
    .carousel {
      .app-ui-carousel.app-ui-carousel-slider {
        padding: 0 70px;
      }
    }
  }

  @media (max-width: $screen-laptop) {
    .carousel {

      .app-ui-carousel.app-ui-carousel-slider {
        padding: 0 30px;
      }
    }

    &-product-cards {
      gap: 24px;
    }

    &-footer {
      justify-content: center;
      gap: 12px;
    }
  }
  @media (max-width: $screen-mobile) {
    .carousel {
      margin: 0 auto;

      .app-ui-carousel.app-ui-carousel-slider {
        padding: 0;
      }
    }
    &-title {
      flex-direction: column;
    }

    &-footer {
      flex-direction: column;
      align-items: center;

      &__content {
        width: 100%;
        flex-direction: column;
      }
    }
  }
}

