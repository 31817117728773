@import "@styles/screen-sizes";

.choose-delivery-method-cards {
  display: flex;
  gap: 23px;
  flex-wrap: wrap;
  align-items: center;

  .express-hint {
    max-width: 204px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .app-select-card {
    text-wrap: nowrap;
  }
}


@media (max-width: $screen-mobile) {
  .choose-delivery-method-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}
