@import "@styles/screen-sizes";

.callback-form {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__footer {
    div {
      margin-bottom: 16px;
    }
  }
}
@media (max-width: $screen-mobile) {
  .callback-form {
    &__footer {
      div {
        margin-bottom: 16px;
      }
    }
  }

}
