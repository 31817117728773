@import "@styles/screen-sizes";

.history-page {
  margin: 32px 0 0 0;

  .ant-table {
    height: calc(100vh - 129px);
    overflow-y: hidden;

    .ant-table-cell-scrollbar {
      display: none;
    }
  }
}

@media (max-width: $screen-laptop) {
  .history-page {
    .ant-table {
      height: calc(100vh - 123px);
    }
  }
}
