@import "@styles/screen-sizes";

.public-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-background);
  height: 100%;
  min-height: 100vh;


  &-content {
    max-width: 1408px;
    width: 100%;
    padding: 0 20px 0 20px;
  }
}

@media (max-width: $screen-laptop) {
  .public-layout {
    &-content {
      max-width: 1025px;
    }
  }
}

@media (max-width: $screen-tablet) {
  .public-layout {
    &-content {
      max-width: 600px;
      padding: 0;
    }

    &-footer {
      padding: 0 0 122px 0;
    }
  }
}

@media (max-width: $screen-mobile) {
  .public-layout {
    &-content {
      padding: 0 20px;
    }
  }
}


