.referral-link-wrapper {
  background-color: var(--color-hover);
}

.referral-link__link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  & .app-ui-text {
    margin-top: 4px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
