.app-ui-input {
  width: 100%;
  height: 54px;
  position: relative;

  &-textarea {
    height: 108px;
    overflow: hidden;
    border-radius: 12px;

      .ant-input {
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-gray-medium);
          border-radius: 2px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
  }


  //Label
  & .label {
    z-index: 1;
    transition: all 0.3s ease;
    color: var(--color-gray-medium);
    position: absolute;
    left: 14px;
    pointer-events: none;

    &.app-input-left-icon-label {
      left: 42px;
    }

    &.label-small {
      top: 10px
    }

    &.label-placeholder {
      top: 17px
    }
  }

  //Icon
  & .icon {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  & .left-icon {
    position: absolute;
    left: 14px;
    top: 15px;
  }


  //ANT
  & .ant-input {
    background-color: transparent;

    //Mask
    &[class*='mask-phone'] {
      padding: 24px 9px 12px 9px;
      box-sizing: border-box;
      margin: 0;
      color: rgba(0, 0, 0, 0.88);
      list-style: none;
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      background-color: transparent;
      background-image: none;
      border-width: 1px;
      border-style: solid;
      transition: all 0.2s;

      &.app-input-filled {
        border-color: var(--color-gray-medium);
      }

      &.error {
        border-color: var(--color-error);
      }

      &:active, &:focus {
        border-color: var(--color-blue);
        box-shadow: none;
      }

      &:focus-within {
        outline: 0;
      }

      &:focus-visible {
        outline-offset: 0;
      }
    }

    line-height: 20px;
    padding: 24px 14px 12px 14px;
    font-size: 16px;
    font-family: inherit;
    border-radius: 12px;
    height: 54px;
    border-color: var(--color-gray-mini);

    &.app-input-icon {
      padding-right: 52px;
    }

    &.app-input-left-icon {
      padding-left: 42px;
    }

    &.app-input-filled {
      border-color: var(--color-gray-medium);
    }

    &:hover {
    }

    &:active, &:focus {
      border-color: var(--color-blue);
      box-shadow: none;
    }

    //Without Label
    &.without-label {
      padding: 0 14px;
    }
  }
}
