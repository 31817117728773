@import "@styles/screen-sizes";


.app-ui-tabs {
  .ant-tabs {
    &-top {
      ::before {
        display: none;
      }
    }

    &-nav {
      &-wrap {
      }

      &-list {
        width: 100%;
        background-color: var(--color-white);
        border-radius: 8px;
        padding: 4px;
        justify-content: space-between;
      }
      ::after {
        display: none;
      }
    }

    &-tab {
      padding: 4px 10px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-gray-max);

      &:hover, &:active {
        color: var(--color-gray-medium);
      }

      &-active {
        background-color: var(--color-black);
        border-radius: 8px;
        border: none;
        color: var(--color-white);
      }

      &-btn {
        color: inherit !important;
        text-shadow: none !important;
      }
    }

    &-ink-bar {
      display: none;

      &-animated {
      }
    }
  }
}


@media (max-width: $screen-laptop) {
  .ant-tabs {
    width: 100%;
  }
}
