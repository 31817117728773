.empty-data__wrapper {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  border-radius: 24px;

  .empty-data {
    padding: 0 20px;
  }

  .empty-data, .empty-data__left {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title {
      text-align: center;
    }

    &__description {
      margin-top: 8px;
    }

    &__btn {
      margin-top: 24px;
    }
  }


  .empty-data__left {
    padding: 24px;
    align-items: flex-start;

    & .empty-data__title {
      text-align: left;
      flex: 0;
    }

    & .empty-data__description {
      margin-top: 16px;
      flex: 1;
    }

    & .empty-data__btn {
      flex: 0;
      margin-top: 0;
    }
  }
}
