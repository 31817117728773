.person-info {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.limited-width {
    max-width: 159px;
  }
}

.dropdown-select-margin-top {
  top: 83px !important;
}
