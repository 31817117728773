.score-info {
  display: flex;
  gap: 4px;
  line-height: 24px;
  align-items: center;
  width: fit-content;

  &.default {
    background-color: inherit;
  }

  &.background {
    padding: 4px;
    border: 1px solid var(--color-hover);
    border-radius: 14px;
    background-color: var(--color-white);
  }
}
