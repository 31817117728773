@import "@styles/screen-sizes";

.public-navbar {
  width: 100%;
  height: 100px;
  background-color: var(--color-white);
  margin-bottom: 20px;
  position: sticky;
  z-index: 2;
  top: 0;

  &__container {
    margin: 0 auto;
    height: 100%;
    max-width: 1408px;
    padding: 0 15px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 58px;

    &__links {
      display: flex;
      gap: 32px;
    }
  }

  &__right {
    display: flex;
    gap: 24px;
    align-items: center;

    &__phone {
    }
  }

  .heightZIndex {
    z-index: 1080;
  }

  .company-img {
    width: 174px;
    height: 50px;
    margin-top: -8px
  }
}

@media (max-height: 600px) {
  .ant-tour {
    &-inner {
      height: 100%;
    }
  }
}

@media (max-height: 400px) {
  .ant-tour {
    &-inner {
      height: 100%;
    }
  }
}

@media (max-height: 300px) {
  .ant-tour {
    &-inner {
      height: 100%;
    }
  }
}

@media (max-width: $screen-laptop) {
  .public-navbar {
    &__container {
      max-width: 1025px;

    }

    &__left {
    }

    &__right {

    }
  }
}
