.delivery-point-info-card-map {
  width: 306px;
  border-radius: 12px;
  padding: 12px;
  border: 1px solid var(--color-gray-mini);
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--color-white);

  &-description {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__title {
      display: flex;
      justify-content: space-between;
      &-text {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
