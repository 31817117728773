.desktop-navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  padding: 20px 32px;
  border-radius: 0 0 24px 24px;

  &__right {
    display: flex;
    gap: 56px;
    align-items: center;
  }
}
