@import "@styles/screen-sizes";

.delivery-point-info-card {
  max-width: 529px;
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  border: 1px solid var(--color-gray-mini);
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-description {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title {
      display: flex;
      justify-content: space-between;

      &-text {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    &__delivery {
      display: flex;
      gap: 8px;

    }
  }
}


@media (max-width: $screen-tablet) {
  .delivery-point-info-card {
    max-width: 100%;
  }
}


