.app-ui-option-list {
  max-height: 215px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-medium);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }


  &_item {
    cursor: pointer;
    border-radius: 15px;
    padding: 9px 12px 7px 12px;
    color: var(--color-gray-max);

    &.cursor-default {
      cursor: default;

      &.active {
        color: var(--color-gray-max);
        background-color: inherit;
      }

      &:hover {
        background-color: inherit;
      }
    }

    &_content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &.active {
      color: var(--color-black);
      background-color: var(--color-hover);
    }

    &:hover {
      background-color: var(--color-hover);
    }

    & .app-ui-text {
      line-height: 20px;
    }
  }
}
