@import '@styles/screen-sizes';

.latest-orders {
  flex: 1;
  max-width: 693px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 21px;
  }

  .latest-orders__list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .order-card__content {
    }

    &__dropdown {
      min-width: 170px;

      &_label {
        display: flex;
        gap: 8px;
        align-items: flex-end;
      }
    }
  }

  .empty-latest-orders {
    height: 405px;
    max-width: 693px;
  }
}

@media (max-width: $screen-laptop) {
  .latest-orders {
    &__header {
    }

    .latest-orders__list {
      &__dropdown {
        .app-ui-dropdown-select__label {
        }

        &_label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

@media (max-width: $screen-laptop) {
  .latest-orders {
    max-width: 504px;

    &__header {
    }

    .latest-orders__list {
      &__dropdown {
        max-width: 100px;
        min-width: 80px;

        .app-ui-dropdown-select__label {
          gap: 0;
          align-items: flex-end;
        }

        &_label {
        }
      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .latest-orders {
    max-width: 100%;

    &__header {
    }

    .latest-orders__list {
      &__dropdown {
        .app-ui-dropdown-select__label {
        }

        &_label {
        }
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .latest-orders {
    &__header {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    .latest-orders__list {
      &__dropdown {

        .app-ui-dropdown-select__label {
        }

        &_label {
          gap: 4px;

        }
      }
    }
  }
}
