@import "@styles/screen-sizes";
@import "@styles/colors.css";

.cart-navigation-btn {
  position: relative;

  .circle {
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: var(--color-green);
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}

@media (max-width: $screen-laptop) {
  .cart-navigation-btn {
    .app-ui-button.ant-btn.large {
      min-width: 44px;
      height: 44px;
      padding: 10px;
    }

    .circle {
      width: 10px;
      height: 10px;
    }
  }
}
