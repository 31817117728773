@import "@styles/screen-sizes";


.ant-tour {
  width: 273px !important;
  max-height: 504px;

  &-inner {
    border-radius: 24px 4px 24px 24px !important;
    padding: 32px 0;
  }

  &-description {
    padding: 0 !important;
    line-height: 0 !important;

  }

  &-header {
    width: 100%;
    display: none !important;
  }

  &-footer {
    display: none !important;
  }
}

.app-ui-tour-description {
  & .ant-tour-description {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 0 22px;

    &_controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }


  &-footer {
    border-top: 1px solid var(--color-hover);
    padding-top: 24px;
    margin: 0 auto;
  }
}

.ant-tour-placeholder-animated {
  height: 0 !important;
}

@media (max-height: 600px) {
  .ant-tour {
    &-inner {
      height: 80vh;
      overflow-y: auto;
    }
  }
}

@media (max-height: 400px) {
  .ant-tour {
    &-inner {
      height: 70vh;
      overflow-y: auto;
    }
  }
}

@media (max-height: 300px) {
  .ant-tour {
    &-inner {
      height: 60vh;
      overflow-y: auto;
    }
  }
}

