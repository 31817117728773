.product-card-vertical-list {
  display: flex;
  flex-direction: column;
  max-height: 297px;
  overflow-y: auto;
  border: 1px solid var(--color-gray-mini);
  border-radius: 16px;
  padding: 12px;

  &__scroll {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .product-card-wrapper {
    border: none;
    padding: 0;
    width: 100%;

    .product-card {
      flex-direction: row;

      &__img {
        width: 145px;
        height: 82px;

        &_img {
          width: 37.356px;
          height: 65.6px;
        }
      }

      &__description {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
