@import "@styles/screen-sizes";


.order-delivery-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 562px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-row {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      &__track {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}


@media (max-width: $screen-tablet) {

  .order-delivery-info {
    max-width: 100%;
    &__content {
      &-row {
        flex-direction: column;

      }
    }
  }
}

