.app-ui-font-icon {
  display: flex;

  &.cursor-pointer {
    cursor: pointer;
  }

  &.cursor-default {
  }
}
