.app-ui-link {
  width: fit-content;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: var(--color-gray-medium);

  &:hover {
    color: var(--color-gray-mini);
  }

  &:active {
    color: var(--color-gray-max);
  }

  &:disabled {
    color: var(--color-dashed);
  }

  a {
    cursor: pointer;
  }

  &.bordered {
    color: var(--color-blue);
    border-bottom: 1px solid var(--color-blue);

    &:hover {
      border-bottom: 1px solid transparent;
    }

    &:active {
      color: var(--color-blue);
    }

    &:disabled {
      color: var(--color-dashed);
    }
  }
}
