@import "@styles/screen-sizes";

.reset-password-form {
  width: 400px;

  &__title {
    margin-bottom: 56px;
  }


  &__controls {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__button {
      width: 100%;
    }
  }
}

@media (max-width: $screen-laptop) {
  .reset-password-form {
    width: 100%;
    min-width: 200px;
  }
}
