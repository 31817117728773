@import "@styles/screen-sizes";

.score-page {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__laptop__referral {
    max-width: 390px;
  }

  &__title {
    display: flex;
    gap: 32px;
    align-items: flex-end;
    margin-bottom: 26px;
  }

  &__list {
    display: flex;
    gap: 13px;

    > div {
      max-width: 460px;
    }
  }
}

@media (max-width: $screen-laptop) {
  .score-page {
    position: relative;

    &__header {
    }

    &__laptop__client {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 16px;

      &_empty {
        //margin-top: -62px;
      }
    }

    &__laptop__referral {
      .app-ui-tabs {
        .ant-tabs {
          &-nav {
            margin-bottom: 0;
          }

          &-content-holder {
            width: 100%;
            max-width: 1025px;
            position: absolute;
            left: 0;
            top: 50px;

            .orders-by-role__empty-list {
              max-width: 100%;
              height: 357px;
            }
          }

          .orders-by-role__header {
            max-width: 460px;
          }

          .orders-by-role__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
          }
        }
      }
    }

    &__title.client {
      align-items: flex-start;
    }

    &__list {
      > div {
      }
    }
  }
}


@media (max-width: $screen-tablet) {
  .score-page {
    max-width: 600px;

    &__title {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & .app-ui-button .ant-btn {
        padding: 0;
      }
    }

    &__laptop__referral {
      .app-ui-tabs {
        .ant-tabs {
          &-nav {
          }

          &-content-holder {
            top: 80px;
          }

          .orders-by-role__header {
          }

          .orders-by-role__list {

          }
        }
      }
    }

    &__laptop__client {
      &_empty {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: $screen-mobile) {
  .score-page {

    &__title {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      margin-bottom: 24px;

      & .app-ui-button .ant-btn {
      }
    }

    &__laptop__referral {
      .app-ui-tabs {
        .ant-tabs {
          &-nav {
          }

          &-content-holder {
          }

          .orders-by-role__header {
          }

          .orders-by-role__list {

          }
        }
      }
    }

    &__laptop__client {
      &_empty {
      }
    }
  }
}


