.recovery-response {
  background-color: var(--color-light-gray);
  display: flex;
  gap: 10px;
  padding: 20px;
  align-items: center;
  border-radius: 20px;
  margin-top: 36px;

  &__img {
    width: 77px;
    height: 65px;
  }

  &__description {
    color: var(--color-black);
  }
}







