.order-payment-info-wrapper {
  width: 100%;

  .order-payment-info {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      &_item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
