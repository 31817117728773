.app-search-input {
  position: relative;
  &__panel {
    top: 65px;
    position: absolute;
    padding: 12px 8px 12px 0;
    max-height: 360px;
    height: fit-content;
    background-color: var(--color-white);
    box-shadow: 0 8px 30.3px 0 #90959D40;
    border-radius: 24px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    &-items {

      &-item {
        border-bottom: 1px solid var(--color-disabled);

        &:hover {
          background: var(--color-hover);
          cursor: pointer;
          border-bottom: 1px solid var(--color-hover);
        }

        &__content {
          &-default {
            padding: 12px 24px;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }

    &-empty {
      padding: 0 24px;
    }
  }
}
