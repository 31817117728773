
.order-history__mobile-wrapper {
  gap: 12px;
  display: flex;
  flex-direction: column;

  .order-history__mobile-search {
    background-color: var(--color-white);
    border-radius: 12px;
    padding: 12px;
  }

  .order-card__wrapper {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-bottom: 32px;

    .empty-data__wrapper {
      height: 348px;
    }
  }
}

.order-history__mobile-wrapper.loading {
  opacity: 0.4;
}
