.app-ui-toast {
  background-color: var(--color-light-gray);
  width: 360px !important;

  &__message {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.ant-notification {
  &-topRight {
    right: 0;
    top: 20px;
    bottom: auto;
  }

  &-stack {
    &-expanded {
    }
  }

  &-notice-wrapper {
    border-radius: 24px 4px 24px 24px !important;
  }

  &-notice {
    padding: 20px !important;
    border-radius: 24px 4px 24px 24px !important;


    &.app-ui-toast {
      &-closable {
      }
    }

    &-content {
    }

    &-message {

    }

    &-description {

    }

    &-close {

    }
  }
}

